<template>
  <div>
    <div class="form-group">
      <el-form-item label="Field Type">
        <el-select
          class="input_type"
          @change="setInputType"
          v-model="field.input_type"
        >
          <el-option
            v-for="(option, index) of getFilledByOptions"
            :key="index"
            :value="option.value"
            :label="option.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
</template>

<script>
// import templateConstants from "../../../constants/lg-en/templates";
import { mapGetters } from "vuex";
var fieldsList = require("../fields.json");
export default {
  name: "templates-formComponents-FieldAttributes",
  props: ["field"],
  computed: {
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage"
    ]),
    getFilledByOptions() {
      if (
        this.field.input_type == "SINGLE_LINE_TEXT" ||
        this.field.input_type == "PASSWORD" ||
        this.field.input_type == "EMAIL" ||
        this.field.input_type == "MASKED" ||
        this.field.input_type == "HYPER_LINK"
      ) {
        return [
          { name: "PASSWORD", value: "PASSWORD" },
          { name: "EMAIL", value: "EMAIL" },
          { name: "TEXT", value: "SINGLE_LINE_TEXT" },
          { name: "MASKED", value: "MASKED" },
          { name: "HYPER LINK", value: "HYPER_LINK" }
        ];
      }
      if (
        this.field.input_type == "NUMBER" ||
        this.field.input_type == "PHONE_COUNTRY_CODE" ||
        // this.field.input_type == "FAX" ||
        this.field.input_type == "CURRENCY" ||
        this.field.input_type == "ZIP_CODE"

      ) {
        return [
          { name: "NUMBER", value: "NUMBER" },
          { name: "PHONE", value: "PHONE_COUNTRY_CODE" },
          { name: "CURRENCY", value: "CURRENCY" },
          { name: "ZIP_CODE", value: "ZIP_CODE" }
          // { name: "FAX", value: "FAX" },
        ];
      }
      if (
        this.field.input_type == "DATE" ||
        this.field.input_type == "TIME" ||
        this.field.input_type == "DATE_TIME" ||
        this.field.input_type == "DATE_TIME_RANGE" ||
        this.field.input_type == "DATE_RANGE"
      ) {
        return [
          { name: "DATE", value: "DATE" },
          { name: "TIME", value: "TIME" },
          { name: "DATE TIME", value: "DATE_TIME" },
          { name: "DATE TIME RANGE", value: "DATE_TIME_RANGE" },
          { name: "DATE RANGE", value: "DATE_RANGE" }
        ];
      }

      if (
        this.field.input_type == "MULTI_LINE_TEXT" ||
        this.field.input_type == "RICH_TEXT"
      ) {
        return [
          { name: "RICH TEXT", value: "RICH_TEXT" },
          { name: "PASSWORD", value: "PASSWORD" },
          { name: "EMAIL", value: "EMAIL" },
          { name: "TEXT", value: "SINGLE_LINE_TEXT" },
          { name: "NUMBER", value: "NUMBER" },
          { name: "CURRENCY", value: "CURRENCY" },
          { name: "MULTI LINE TEXT", value: "MULTI_LINE_TEXT" }
        ];
      }
      if (
        this.field.input_type == "MULTI_SELECT" ||
        this.field.input_type == "SELECT"
      ) {
        return [
          { name: "SELECT", value: "SELECT" },
          { name: "MULTI SELECT", value: "MULTI_SELECT" }
        ];
      }
      if(this.field.input_type == "IMAGE" ||
      this.field.input_type == "GIF"){
        return [
          { name: "IMAGE", value: "IMAGE" },
          { name: "GIF", value: "GIF" }
        ];
      }
      return this.fieldFilledByOptions;
    }
  },
  data() {
    return {
      fieldFilledByOptions: [
        { name: "SENDER", value: "SENDER" },
        { name: "RECEIVER", value: "RECEIVER" },
        { name: "SENDER OR RECEIVER", value: "SENDER_OR_RECEIVER" }
      ],
      fieldActions: [],
      fieldsAndComponents : {}
    };
  },
  async mounted() {
    await this.getAllFieldList();
  },
  methods: {
    async getAllFieldList() {
      try {
        if (fieldsList) {
          this.fieldActions = [
          ...fieldsList.allFormFields,
          ...fieldsList.allContentFields,
          ...fieldsList.allAdvancedFields,
        ];
          this.fieldActions.forEach((action) => {
              this.fieldsAndComponents[action.inputType] = action;
          });
        }
      } catch (error) {
        console.log("getAllFieldList",error);
      }
    },
    async setInputType() {
      try {
        //let params = "form=true&content=true";
        // await this.$store.dispatch("standardFields/getFieldLists", params);
        if (this.fieldActions) {
          let fieldProps = this.fieldsAndComponents[this.field.input_type];
          if (fieldProps) {
            this.field.min_height = fieldProps.min_height;
            this.field.min_width = fieldProps.min_width;
            this.field.max_height = fieldProps.max_height;
            this.field.max_width = fieldProps.max_width;
            this.field.height = fieldProps.height;
            this.field.width = fieldProps.width;
            this.field.inputType = fieldProps.inputType;
            this.field.components = fieldProps.components;
          }
        }
      } catch (error) {
        console.log(" setInputType",error);
      }
    }
  }
};
</script>

<style lang="scss">
.filledby {
  width: 45%;
}
</style>
